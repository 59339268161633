import React from 'react'
import { UsersApiAdapter } from '../../api/impl/adapters';
import { UsersApi } from 'ftm-api-client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import View from '../../components/global/Page';
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Refresh from '@mui/icons-material/Refresh'
import { useMsal } from '@azure/msal-react';
import Box from '@mui/material/Box'
import ReportProblem from '@mui/icons-material/ReportProblem';
import { loginRequest } from '../../application/util/Configuration';
import { initProfile } from '../../application/redux/slices/users-profile';
import { useAppDispatch } from '../../application/redux/hooks';
import Link from "@mui/material/Link";
import {Link as RouterLink} from 'react-router-dom'

/**
 * A page for handling redirect from SSO providers.
 * @returns {JSX.Element}
 */
const InitializationScreen = () => {

    /**
     * 1. Check the auth method.
     *      -> a. If 'mongo', redirect to login
     *      -> b. If 'azure', proceed to 2.
     * 2. Check the params from the URL.
     *      -> a. If authorization_code and state in URI, proceed to 3.
     *      -> b. If authorization_code and state in URI, proceed to 1a.
     * 3. Send a response to the API with the authorization code.
     * 4. Intercept user profile response and navigate to home redirect screen.
     */

    /**
     * Represents whether data is being loaded into the application.
     */
    const [loading, setLoading] = React.useState(true);

    /**
     * Reference to the MSAL instance.
     */
    const {instance} = useMsal();

    /**
     * Represents whether an error has occurred.
     */
    const [error, setError] = React.useState(false);

    /**
     * Instance of the APIAdapter for Users.
     */
    const usersApi = new UsersApiAdapter();

    /**
     * Represents the user query search params.
     */
    const [queryParams] = useSearchParams();

    /**
     * Represents the react-router-dom navigation hook.
     */
    const navigate = useNavigate();

    /**
     * Hook into the global redux dispatch.
     */
    const dispatch = useAppDispatch();

    /**
     * Initialize the user profile.
     */
    const initializeUserProfile = async () => {
        try {
            const token = await instance.acquireTokenSilent(loginRequest)
            if (!token) return navigate('/login');
            const response = await usersApi.loginUserSso(token.accessToken);
            setLoading(false);
            localStorage.setItem('token', response.accessToken)
            initProfile(dispatch, () => {
                navigate('/home');
            });
        } catch (error) {
            setError(true);
            setLoading(false)
            console.error(error);
        }
    }

    React.useEffect(() => {
        if (loading) {
            initializeUserProfile();
        }
    }, [loading])

    /**
     * Request to re-attempt loading the user's profile.
     */
    const handleRetry = () => {
        setError(false);
        setLoading(true);
    }

    return (
        <View>
            <Container maxWidth="lg">
            <Box sx={{textAlign: 'center', paddingTop: '22%', height: '100%', verticalAlign: 'center'}}>
                {error ? <ReportProblem color='secondary' fontSize='large'/> : <CircularProgress/>}
                <Typography sx={{mb: 1}} variant='h4'>
                    {error ? "An Error Occurred" : loading ? "Initializing Application" : "Redirecting..."}
                </Typography>
                <Typography 
                    sx={{mb: 2}}
                    color="textSecondary"
                    variant="body2"
                    >{error ? <>An error occurred while retrieving your information. Please try again momentarily. <br/> Alternatively, you may return to the <Link component={RouterLink} to={'/login'}>login</Link> screen</> : "Please wait while we gather information about your user profile."}</Typography>
                {error && <Button
                    startIcon={<Refresh/>}
                    onClick={handleRetry}
                >Retry</Button>}
                <br/>
                <br/>
            </Box>
            </Container>
        </View>
    )
}

export default InitializationScreen;