import React from 'react'
import debounce from '@mui/material/utils/debounce'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import LoadingButton from '@mui/lab/LoadingButton'
import InputField from '../../components/global/InputField'
import { CategoriesApi, Category, ProductTypesApi, ResponseCategoryFromJSON } from 'ftm-api-client'
import { getGlobalConfig } from '../../api/Api'
import styled from '@mui/material/styles/styled'
import TreeItem, {TreeItemProps, treeItemClasses} from '@mui/lab/TreeItem'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FolderTwoTone from '@mui/icons-material/FolderRounded'
import SettingsTwoTone from '@mui/icons-material/SettingsRounded'
import TreeView from '@mui/lab/TreeView'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Search from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import grey from '@mui/material/colors/grey'
import { ProductTypesApiAdapter } from '../../api/impl/adapters'
import Paper from '@mui/material/Paper'
import LoadingSection from '../global/LoadingSection'

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(0.5),
        borderTopLeftRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        borderBottomLeftRadius: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            {...other}
        />
    );
}

/**
 * Represents a hierarchical, filterable list of categories and product types to
 * be presented to a user.
 * @param props 
 * @returns {JSX.Element}
 */
const ProductDirectory = (props: ProductDirectoryProps): JSX.Element => {

    const config = getGlobalConfig()

    const categoriesApi = new CategoriesApi(config);

    const productTypesApi = new ProductTypesApiAdapter();

    /**
     * Represents whether data is being loaded into the component.
     */
    const [loading, setLoading] = React.useState<boolean>(true);

    /**
     * Data structure which stores the current product category tree.
     */
    const [productCategoryTree, setProductCategoryTree] = React.useState<any>({
        "root": {
            name: "Directory",
            childNodes: [],
        }
    })

    /**
     * Represents the displayed copy of the product category tree.
     */
    const [filteredProductCategoryTree, setFilteredProductCategoryTree] = React.useState<any>()


    /**
     * Renders the services folder tree recursively.
     */
    const renderCategoryFolderTree = (childNodes: any[] = [], index=0): any => {
        if (index >= childNodes.length) return;
        return <><StyledTreeItem onClick={() => { 
            if (props.onEndNodeClicked && childNodes[index].isProductType) props.onEndNodeClicked(childNodes[index])
         }} labelIcon={childNodes[index].isProductType ? SettingsTwoTone : FolderTwoTone} nodeId={childNodes[index].pid || 'root'} labelText={childNodes[index].name}>
            {renderCategoryFolderTree(childNodes[index].childNodes)}
        </StyledTreeItem>
            {renderCategoryFolderTree(childNodes, index+1)}
        </>
    }

    /**
     * Fetches all product types for the given category.
     * @param categoryPid the pid of the category
     */
    const fetchProductTypesForCategory = async (categoryPid: string) => {
        
        const productTypes = await productTypesApi.findAll({
            q: `{"categoryPid": "${categoryPid}"}`
        })
        if (productTypes) {
            return productTypes;
        }

        return null;
    }

    React.useEffect(() => {
        initCategoryTree();
    }, [])

    const fetchCategoryTree = async (currentChildren: Category[] = [], index: number = 0, childNodes: any[] = []): Promise<any> => {
        let newChild: any;
        if (currentChildren[index])
            newChild = {...currentChildren[index], childNodes: []}
        if (index >= currentChildren.length) {
            return childNodes;
        }
        const getChildrenResponse = await categoriesApi.getCategories(
            {q: `{"parentCategoryPid": "${currentChildren[index].pid}"}`})
        let productTypes = await fetchProductTypesForCategory(currentChildren[index].pid!);
        if (productTypes) {
            productTypes?.forEach(productType => {
                productType.childNodes = [] 
                productType.isProductType = true
            });
            newChild.childNodes = productTypes;
        }
        if (getChildrenResponse) {
            let categoryResponse = ResponseCategoryFromJSON(getChildrenResponse)
            if (categoryResponse && categoryResponse.data) {
                const subCategories = await fetchCategoryTree(categoryResponse.data);
                if (subCategories) newChild.childNodes = newChild.childNodes.concat(subCategories)
                let newChildNodes = childNodes.concat(newChild);
                return await fetchCategoryTree(currentChildren, index+1, newChildNodes)
            }
        }
        return newChild;
    }

    /**
     * Fetch and treat all services
     */
    const initCategoryTree = async (): Promise<any> => {
            const data = await categoriesApi.getCategories({q: `{"parentCategoryPid": null}`})
            if (data && data.data) {
                let categoryPidToTree = {...productCategoryTree}
                const categoryResponse = ResponseCategoryFromJSON(data);
                if (categoryResponse && categoryResponse.data) {
                    let childNodes = await fetchCategoryTree(categoryResponse.data)
                    categoryPidToTree['root'].childNodes = childNodes;
                    setProductCategoryTree(categoryPidToTree);
                    setFilteredProductCategoryTree(categoryPidToTree);
                    setLoading(false);
                }
            }
    }

    /**
     * Handles actionn on search filter change.
     * @param searchFilter 
     */
    const onSearchFilterChange = (searchFilter: string) => {
        let newFilteredCategoryTree = {...productCategoryTree};
        newFilteredCategoryTree['root'] = handleDepthSearchFilter(searchFilter, newFilteredCategoryTree['root']);
        setFilteredProductCategoryTree(newFilteredCategoryTree);
    }

    /**
     * Traverses each node of the tree until encountering a leaf. If a match is found during
     * traversal, invokes the recursive call with "foundMatch" true. Only clips branches
     * such that the branch contains zero matches.
     * @param searchText the text to search for
     * @param {any} node the current node in the recursion
     * @param {boolean} foundMatch whether a match was found
     */
    const handleDepthSearchFilter = (searchText: string, node: any, foundMatch: boolean = false) => {
        const currentNameMatches = node.name.toLowerCase().includes(searchText.toLowerCase());
        if (!node.childNodes) {
            if (!currentNameMatches && !foundMatch) return null; // No match, so don't push anything.
            return node;
        }
        const newNode = {...node}, matchesFilter: boolean = newNode.name === 'Directory' ? true : currentNameMatches;
        let newChildNodes: any[] = [];
        let foundDeeperMatch = false;
        newNode.childNodes.map((childNode: any) => {
            const nodeToPush = handleDepthSearchFilter(searchText, childNode, matchesFilter);
            if (nodeToPush) {
                newChildNodes.push(nodeToPush);
                foundDeeperMatch = true;
            }
        })
        newNode.childNodes = newChildNodes;
        return !foundDeeperMatch && !matchesFilter && newNode.name !== 'Directory' ? null : newNode;
    }

  return (
    <div>
        <InputField onChange={(e: any) => { onSearchFilterChange(e.target.value) }} InputProps={{
            startAdornment: <InputAdornment position='start'><Search/></InputAdornment>
        }} size='small' placeholder='Search...' fullWidth/>
        <LoadingButton style={{marginTop: 15, marginBottom: 15}} size='small' variant='contained' color='primary'>Collapse All</LoadingButton>
        <Card variant='outlined'>
            {loading ? <LoadingSection/> : <>
                <CardContent><Typography variant='body2' color='textSecondary'><strong>Product Types Directory</strong></Typography></CardContent>
                <Divider/>
                <CardContent style={{minHeight: '400px'}}>
                            <TreeView
                                defaultExpanded={['root']}
                                defaultCollapseIcon={<ArrowDropDownIcon/>}
                                defaultExpandIcon={<ArrowRightIcon/>}
                            >
                                {filteredProductCategoryTree  && renderCategoryFolderTree([filteredProductCategoryTree['root']])}
                            </TreeView>
                </CardContent>
            </>}
        </Card>
    </div>
  )
}

type ProductDirectoryProps = {
    onEndNodeClicked?: Function
}

export default ProductDirectory