import React, { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ListItemButton from '@mui/material/ListItemButton'
import ListSubheader from '@mui/material/ListSubheader'
import useMediaQuery from '@mui/material/useMediaQuery'
import Home from '@mui/icons-material/HouseRounded'
import FolderOpen from '@mui/icons-material/FolderOpenRounded'
import ShoppingCart from '@mui/icons-material/ShoppingCartRounded'
import Settings from '@mui/icons-material/SettingsRounded'
import Logout from '@mui/icons-material/LogoutRounded'
import BugReport from '@mui/icons-material/BugReport'
import MailIcon from '@mui/icons-material/Mail';
import ExpandLess from '@mui/icons-material/ExpandLess'
import LogoDev from '@mui/icons-material/LogoDev'
import ExpandMore from '@mui/icons-material/ExpandMore'
import RoomPreferences from '@mui/icons-material/RoomPreferencesRounded'
import {useLocation, useNavigate } from 'react-router-dom';
import Authentication from '../../api/Authentication'
import Show from './Show'
import Business from '@mui/icons-material/BusinessRounded'
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettingsRounded'
import CorporateFare from '@mui/icons-material/CorporateFareRounded'
import AccountTree from '@mui/icons-material/AccountTreeRounded'
import MenuBook from '@mui/icons-material/MenuBookRounded'
import Assessment from '@mui/icons-material/AssessmentRounded'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../application/redux/slices/users-profile'

/**
 * The NavigationBar is a navigation panel that displays links
 * to pages that can be navigated to. The panel accepts a NavigationPanel
 * object that can be nested to render nested menu items.
 * @author chrisrinaldi
 * @since 20 March, 2022
 * @returns {JSX.Element}
 */
const Navbar = (props: {navOpen: boolean, setNavOpen: Function}) => {

    /**
     * A react-router-dom hook that obtains the current
     * location.
     */
    const location = useLocation();

    /**
     * Represents the navigation hook.
     */
    const navigate = useNavigate();

    /**
     * Represents the array of expanded 'keys' in the navBar.
     */
    const [expanded, setExpanded] = useState([''])

    /**
     * Represents the react-redux dispatch.
     */
    const dispatch = useDispatch();

    /**
    * A media query that represents whether or not the application
    * is in mobile-responsive mode or not.
    */
    let mobileResponsive = useMediaQuery('(max-width:875px)');

    /**
     * Represents the type of each entry in the navigation items.
     */
    type NavigationItem = {
        title: string,
        to: string,
        icon: JSX.Element,
        items?: Array<NavigationItem>,
        action?: Function
    }

    /**
     * Represents the navigation items.
     */
    const navigationItems: Array<NavigationItem> = [
        {
            title: 'Home',
            to: '/',
            icon: <Home/>,
        },
        {
            title: 'Report a Bug',
            to: '/bug-report',
            icon: <BugReport/>,
        }
    ]

    const lowerNavigationItems: Array<NavigationItem> = [
        {
            title: 'Settings',
            to: '/settings',
            icon: <Settings/>
        },
        {
            title: 'Logout',
            to: '/logout',
            icon: <Logout/>,
            action: function() {
                logoutUser(dispatch);
                navigate('/')
            }
        }
    ]

    const employeeMenu: Array<NavigationItem> = [
        {
            title: 'Products',
            to: '/employee/products',
            icon: <ShoppingCart/>
        },
        {
            title: 'My Organization',
            to: '/employee/organization',
            icon: <RoomPreferences/>
        },
        {
            title: 'Product Directory',
            to: '/employee/products/types',
            icon: <MenuBook/>
        },
        {
            title: 'Reports',
            to: '/reports',
            icon: <Assessment/>
        },
    ]

    const developerMenu: Array<NavigationItem> = [
        {
            title: 'Industries',
            to: '/industries',
            icon: <CorporateFare/>
        },
        {
            title: 'Organizations',
            to: '/organizations',
            icon: <Business/>
        },
        {
            title: 'Product Directory',
            to: '/categories',
            icon: <FolderOpen/>,
        },
        {
            title: 'Attributes',
            to: '/attributes',
            icon: <AccountTree/>,
        },
        {
            title: 'Users',
            to: '/employee/clients',
            icon: <AdminPanelSettings/>
        },
        {
            title: 'Developer',
            to: '/experimental',
            icon: <LogoDev/>,
            items: [
                {
                    title: 'Notifications',
                    to: '/employee/notifications',
                    icon: <MailIcon/>
                },
                {
                    title: 'Reports',
                    to: '/employee/notifications',
                    icon: <LogoDev/>
                }
            ]
        },
    ]

    /**
     * Handles action taken when a nested item is clicked. If
     * it is in the array of expanded, removes it, otherwise
     * adds it.
     * @param {string} key represents the key to handle click for.
     */
    const handleClick = (key: string) => {
        expanded.includes(key) ? setExpanded(expanded.filter(s => s !== key)) : setExpanded([...expanded, key])
    }

    /**
     * Creates the nav menu by mapping the array of navigation items
     * to material-ui components. If the item located at the current
     * index contains an 'items' field, maps the items to a nested menu.
     * @param items represents the items to map
     * @param sublist represents whether this is a sublist
     * @returns 
     */
    const createNav = (items: Array<NavigationItem>, sublist: boolean=false, previousPadding: number=2) => {
        return items.map(navItem => (
                    Object.keys(navItem).includes('items') ? 
                    <>
                    <ListItemButton sx={{pl: previousPadding}} onClick={() => {handleClick(navItem.title)}} key={navItem.title} selected={location.pathname === navItem.to}>
                        <ListItemIcon>
                            {navItem.icon}
                        </ListItemIcon>
                        <ListItemText 
                            primary={navItem.title}
                            primaryTypographyProps={{
                                color: location.pathname === navItem.to ? 'primary' : 'text.secondary',
                                sx: { fontWeight: 500 },
                                variant: 'body2'
                              }}
                            />
                        {expanded.includes(navItem.title) ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton> 
                    <Collapse in={expanded.includes(navItem.title)} timeout='auto' unmountOnExit>
                            <List component='div' disablePadding>
                                {createNav(navItem.items as NavigationItem[], true, previousPadding+2)}
                            </List>
                        </Collapse>
                    </>
                    :
                        <ListItemButton 
                        onClick={() => {
                            mobileResponsive && props.setNavOpen(false)
                            if (navItem.action) {
                                navItem.action()
                            } else {
                                navigate(navItem.to)
                            }
                            }} sx={{pl: previousPadding}} key={navItem.title} selected={location.pathname === navItem.to}>
                            <ListItemIcon>
                                {navItem.icon}
                            </ListItemIcon>
                            <ListItemText 
                            primary={navItem.title}
                            primaryTypographyProps={{
                                color: location.pathname === navItem.to ? 'primary' : 'text.secondary',
                                sx: { fontWeight: 500 },
                                variant: 'body2'
                              }}
                            />
                        </ListItemButton>
            ))
    }

    /**
     * Represents the width of the drawer.
     */
    const drawerWidth = 280;

  return (
    <Drawer
        variant={!mobileResponsive ? (Authentication.isAuthenticated() ? 'permanent' : 'temporary') : 'temporary'}
        open={props.navOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto',             scrollbarWidth: 'none',
            msOverflowStyle: 'none', }}>
        <List component='nav'>
            <ListSubheader>
                Navigation
            </ListSubheader>
          {createNav(navigationItems)}
        </List>
        <Show privileges={['employee', 'developer']}>
            <Divider />
            <List component='nav'>
            <ListSubheader>
                    Employee Menu
                </ListSubheader>
                {createNav(employeeMenu)}
            </List>
          </Show>
          <Show privileges={['developer']}>
            <Divider />
            <List component='nav'>
            <ListSubheader>
                    Developer Menu
                </ListSubheader>
                {createNav(developerMenu)}
            </List>
          </Show>
          <Divider />
          <List component='nav'>
              <ListSubheader>
                  Preferences
              </ListSubheader>
          {createNav(lowerNavigationItems)}
          </List>
        </Box>
      </Drawer>
  )
}

export default Navbar