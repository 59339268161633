import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styled from '@mui/material/styles/styled'
import React, { ReactNode, useCallback } from 'react'
import { useDropzone, FileWithPath } from 'react-dropzone'
import FileUpload from '@mui/icons-material/FileUpload'
import grey from '@mui/material/colors/grey'

/**
 * Returns a functional component that allows a user
 * to choose multiple files from their drive and queue
 * them for upload or submission. When files are rejected,
 * or accepted displays a snackbar with the reason or
 * justification.
 * @author chrisrinaldi
 * @date 7 April, 2022
 * @returns {React.ReactElement}
 */
const FilePicker = (props: FilePickerProps) => {

  /**
   * A callback function that executes when the user successfully drops
   * files into the filepicker component. 
   */
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (props.onFilesSelected) {
      props.onFilesSelected(acceptedFiles);
    }
  }, [])

    /**
     * Destructures the root/input props as well as the accepted files
     * from the dropzone hook.
     */
    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({ onDropAccepted: onDrop });

    /**
     * Represents a mapping of the files that have been accepted into the File Picker.
     */
     const fileList = (files: FileWithPath[]): ReactNode => (
        <div key='files' style={{textAlign: 'left'}}>
        {files.length} files - Total Size: {Math.floor(files.reduce((acc, file) => acc + file.size, 0) / 1000)} kB
        </div>
      );

      /**
       * Represents the container's styling.
       */
    const containerStyle = {
        width: '100%',
        height: '100%',
        minHeight: props.height || '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '3px dashed #ccc',
        borderRadius: '5px',
        padding: '10px',
        '&:hover': {
            border: '3px dashed #ddd'
        },
        textAlign: 'center',
        backgroundColor: grey[200],
        ...props.containerProps
    }


  return (
      <Box sx={containerStyle as any}>
          <div {...getRootProps({className: 'dropzone'})} style={{opacity: 0.5}}>
            <input {...getInputProps()} />
            <FileUpload fontSize='large'/>
            <p><strong>Choose a file</strong> or drag and drop here...</p>
            <Button onClick={() => {
                
                open()}} variant="contained" color='secondary'>BROWSE</Button>
            {acceptedFiles.length > 0 && (
            <aside>
            <h4>Files</h4>
            <ul>{fileList(acceptedFiles)}</ul>
            </aside>
            )}
          </div>
      </Box>
  )
}

/**
 * Represents the props for this component.
 */
export interface FilePickerProps {

    /**
     * Optional parameters that can be specified to the
     * styled container component.
     */
    containerProps?: React.ReactPropTypes,

    /**
     * Dictates the height of the FilePickerContainer.
     */
    height?: string | number,

    /**
     * Handles a file upload. When the selected files
     * are changed, passes the array of FileWithPath
     * as parameters.
     */
    onFilesSelected?: (files: FileWithPath[]) => void
}

export default FilePicker