import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import useTheme from '@mui/material/styles/useTheme'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Avatar from '@mui/material/Avatar'
import React, { useState } from 'react'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { UserResponse } from 'ftm-api-client'
import AutocompleteSearch from '../global/AutocompleteSearch'
import { OrganizationsApiAdapter } from '../../api/impl/adapters'

/**
 * The UserForm is a form that allows the user to either edit or create a new
 * user or edit the user if one is provided thru props.
 * 
 * You can pass several props to this component to allow it to either be an uncontrolled
 * component (manage its own 'client' object) or a controlled component (have it utilize
 * a React state mutator function to manage a client).
 * @author chrisrinaldi
 * @since 21 March, 2022
 * @see ClientEditFormProps
 * @returns {JSX.Element}
 */
const UserForm = (props: ClientEditFormProps) => {

  const [client, setClient] = useState<UserResponse>(props.initialUser ? props.initialUser : {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    addressStreet1: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    privilegePid: '',
    organizationPid: "",
    password: ""
  })

  const [activeRole, setActiveRole] = useState('none')

  /**
   * Represents the styles for the text fields.
   */
  const styles = {
    header: {
      marginTop: '10px',
      marginBottom: '15px'
    },
    textField: {
      marginBottom: '20px',
    },
    button: {
      marginTop: '30px',
      marginBottom: '20px'
    }
  }


  /**
   * Handles action taken when the form has been submitted.
   */
  const handleSubmit = () => {

    if (props.initialUser && props.onUpdate) props.onUpdate(props.initialUser!.pid!, client)
    else if (props.onSubmit) {
      props.onSubmit!(client)
    }

  }

  /**
   * Handles value change when a different role
   * is selected.
   * @param e 
   */
  const handleRoleChange = (e: any) => {
    setActiveRole(e.target.value);
    
    setClient({
      ...client,
      privilegePid: e.target.value
    })
  }

  return ( <Card>
    <CardContent>
    <Grid container spacing={1}>
      <Grid item>
        <Avatar/>
      </Grid>
      <Grid item>
        <Typography>Signup User</Typography>
        <Typography variant='body2' color='textSecondary'>Use the form below to create or edit a user.</Typography>
      </Grid>
      </Grid>
    </CardContent>
    <Divider/>
    <CardContent>
      <Typography variant='h6'>Account Settings</Typography>
      <Typography variant='body2' color='textSecondary'>Specify login information for this user.</Typography>
      <br/>
      <Grid item xs={6}>
          <TextField variant='filled' size='small' defaultValue={client.email} onChange={(e) => {setClient({...client, email: e.target.value})}} fullWidth placeholder='Email address' type='email' sx={styles.textField}/>
      </Grid>
          {!props.initialUser && <TextField variant='filled' size='small' onChange={(e) => {setClient({...client, password: e.target.value})}} fullWidth placeholder='Password' type='password' sx={styles.textField}/>}
          <Select
            variant='filled'
            size='small'
            fullWidth
            sx={styles.textField}
            value={activeRole}
            label="Select role"
            onChange={handleRoleChange}
          >
            <MenuItem value={'none'}><i>Select a privilege...</i></MenuItem>
            {Object.keys(props.privilegePidToPrivilege).map(rolePid => (
              <MenuItem value={rolePid}>{props.privilegePidToPrivilege[rolePid].name}</MenuItem>
            ))}
          </Select>
    </CardContent>
    <Divider/>
    <CardContent>
    <Typography variant='h6'>Organization Information</Typography>
    <Typography variant='body2' color='textSecondary'>Specify additional information for the user, including assignment of role and organization.</Typography>
    <br/>
    <AutocompleteSearch textFieldProps={{variant: 'filled'}} apiInstance={new OrganizationsApiAdapter()}/>
    </CardContent>
    <Divider/>
    <CardActions>
      <LoadingButton loading={props.loading} onClick={handleSubmit} variant='contained' sx={styles.button}>Save</LoadingButton>
    </CardActions>
    </Card>
  )

}

/**
 * Reprsents a typed props object for this
 * component.
 */
interface ClientEditFormProps {
    initialUser?: UserResponse,
    privilegePidToPrivilege: any,
    onUpdate?: (userPid: string, newUser: UserResponse) => void,
    onSubmit?: Function,
    loading: boolean
}


export default UserForm