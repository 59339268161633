import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import LoadingButton from '@mui/lab/LoadingButton'
import InputField from './InputField'
import FilePicker from './FilePicker'
import DialogActions from '@mui/material/DialogActions'
import MenuItem from '@mui/material/MenuItem'

/**
 * Allows the user to report a bug.
 * @returns {JSX.Element}
 */
const BugReportForm = () => {

    /**
     * The types of bugs that the user can select from.
     */
    const bugReportTypeOptions = [
        "Graphical/UI Error",
        "Functional Error",
        "Server Error",
        "Other"
    ]

    /**
     * Represents the hook into the formik functionality.
     */
    const formik = useFormik({
        isInitialValid: false,
        initialValues: {
            email: '',
            bugReportType: "none",
            message: "",
            submit: null
        },
        validationSchema: Yup.object().shape({
            email: Yup
                .string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
            message: Yup
                .string()
                .min(25, "Must be >= 25 characters in length")
                .max(500)
                .required("Must enter a message!"),
            bugReportType: Yup
                .string()
                .oneOf(bugReportTypeOptions)
                .required("Please select an issue type")
        }),
        onSubmit: async (values, helpers) => {
            try {

            } catch (err: any) {

                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            }
        }
    });

    return (
        <Card>
            <CardContent>
                <form onSubmit={formik.handleSubmit}>
                    <InputField
                        sx={{ mb: 3 }}
                        placeholder="Email address"
                        variant='filled'
                        error={Boolean(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        label="Email Address"
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    <InputField
                        fullWidth
                        sx={{ mb: 3 }}
                        onChange={formik.handleChange}
                        label="Issue Type"
                        select
                        name="bugReportType"
                        error={Boolean(formik.touched.bugReportType && formik.errors.bugReportType)}
                        value={formik.values.bugReportType}
                    >
                        <MenuItem
                            key="none"
                            value="none"
                        >Select an issue type</MenuItem>
                        {bugReportTypeOptions.map((option) => (
                            <MenuItem
                                key={option}
                                value={option}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </InputField>
                    <InputField
                        variant='filled'
                        sx={{ mb: 5 }}
                        error={Boolean(formik.touched.email && formik.errors.email)}
                        fullWidth
                        multiline
                        minRows={7}
                        placeholder="Enter a brief description of the bug, including steps to reproduce and any additional information."
                        helperText={formik.touched.message && formik.errors.message}
                        label="Message"
                        name="message"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.message}
                    />
                    <FilePicker />
                    <DialogActions>
                        <LoadingButton
                            color="primary"
                            type="submit"
                            disabled={!formik.isValid}
                            variant="contained"
                            loading={formik.isSubmitting}
                        >
                            Send Report
                        </LoadingButton>
                    </DialogActions>
                </form>
            </CardContent>
        </Card>
    )
}

interface BugReportFormProps {

}

export default BugReportForm