import React, {useEffect} from "react";
import View from "../../components/global/Page";
import Container from "@mui/material/Container";
import Header from "../../components/global/Header";
import TextField from "@mui/material/TextField";
import {BarChart} from "@mui/x-charts/BarChart";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {LineChart, ScatterChart} from "@mui/x-charts";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const DashboardScreen = () => {

    useEffect(() => {

    }, []);

    const data = [
        {
            id: 'data-0',
            x1: 329.39,
            x2: 391.29,
            y1: 443.28,
            y2: 153.9,
        },
        {
            id: 'data-1',
            x1: 96.94,
            x2: 139.6,
            y1: 110.5,
            y2: 217.8,
        }];

    const columns: GridColDef<(typeof rows)[number]>[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,
            editable: true,
            resizable: true
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,
            editable: true,
            resizable: true
        },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            editable: true,
            resizable: true
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
        },
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    return (
        <View>
            <Container maxWidth={'xl'}>
                <Header
                    title={'Dashboard'}
                    breadcrumbs={[
                        {
                            title: "Home",
                            path: '/'
                        },
                        {
                            title: "Dashboard",
                            path: "/search"
                        }
                    ]}
                />
                <Typography variant={'body2'} color={'textSecondary'}>Last time data received: {new Date().toISOString()}</Typography>
                <br/>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Card>
                            <CardContent>
                                <BarChart
                                    height={300}
                                    width={350}
                                    series={[
                                        { data: [35, 44, 24, 34] },
                                        { data: [51, 6, 49, 30] },
                                        { data: [15, 25, 30, 50] },
                                        { data: [60, 50, 15, 25] },
                                    ]}
                                    xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
                                    margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Card>
                            <CardContent>
                                <LineChart
                                    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                                    series={[
                                        {
                                            data: [2, 5.5, 2, 8.5, 1.5, 5],
                                        },
                                    ]}
                                    grid={{
                                        horizontal: true,
                                        vertical: true
                                    }}
                                    width={400}
                                    height={300}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Card>
                            <CardContent>
                                <ScatterChart
                                    width={400}
                                    height={300}
                                    series={[
                                        {
                                            label: 'Series A',
                                            data: data.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
                                        },
                                        {
                                            label: 'Series B',
                                            data: data.map((v) => ({ x: v.x1, y: v.y2, id: v.id })),
                                        },
                                    ]}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <br/>
                <Typography sx={{mb: '10px'}} fontWeight={'700'} variant="body2" color="text.secondary">
                    My Organization
                </Typography>
                <Card>
                    <CardContent>
                        Description
                    </CardContent>
                </Card>
                <br/>
                <Paper>
                    <TextField
                        variant={'outlined'}
                        size={'small'}
                        fullWidth
                        placeholder={'Search statuses...'}
                    />
                </Paper>
                <br/>
                <Paper>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    autoHeight
                />
                </Paper>
            </Container>
        </View>
    )
}

export default DashboardScreen;