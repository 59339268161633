import Container from '@mui/material/Container'
import { Configuration, PrivilegesApi, UserResponse, UsersApi } from 'ftm-api-client'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { defaultConfig } from '../../api/Api'
import UserForm from '../../components/employee/UserForm'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'

/**
 * Renders an EditClientScreen.
 * @returns 
 */
const EditClientScreen = () => {

    const location = useLocation();

    const [client, setClient] = useState<UserResponse | null>(null)

    const [privilegePidToPrivilege, setPrivilegePidToPrivilege] = useState({})

    const [loading, setLoading] = useState<boolean>(false);

    const [galleryPidToGallery, setGalleryPidToGallery] = useState({})

    const navigate = useNavigate()

    const id = useParams();

    const config = new Configuration(defaultConfig)

    const usersApi = new UsersApi(config);

    const privilegesApi = new PrivilegesApi(config)

    /**
     * Fetches data.
     */
    const fetchData = async () => {

        setLoading(true);

        usersApi.getUser({
            "pid": id as any as string
        }).then((result) => {
            if (!result.data || result.data!.length == 0) navigate('/')
            else {
                setClient(result.data![0])
                privilegesApi.getPrivileges().then((response) => {
                    if (response && response.data!.length > 0) {
                        let newPrivilegePidToPrivilege: any = {}
                        response.data!.map((privilege) => {
                            newPrivilegePidToPrivilege[privilege.pid] = privilege;
                        })
                        setPrivilegePidToPrivilege(newPrivilegePidToPrivilege)

                    }
                }, (error) => {})
            }
        }, (error) => {

        })
        
    }

    useEffect(() => {
        fetchData();
    }, [])
    

  return (
      <View>
          <Container maxWidth="lg">
              {
                  client && (
                        <Header 
                            title={'Editing Client: ' + client.firstName + ' ' + client.lastName}
                            breadcrumbs={[
                                {
                                    title: 'Home',
                                    path: '/'
                                },
                                {
                                    title: 'Employee',
                                    path: '/employee/clients'
                                },
                                {
                                    title: 'Users',
                                    path: '/employee/clients'
                                },
                                {
                                    title: 'Edit',
                                    path: `/employee/clients/${client.pid}`
                                }
                            ]}
                        />
                  )
              }
              <br/>
              {client && <UserForm
                loading={loading}
                privilegePidToPrivilege={privilegePidToPrivilege}
                initialUser={client!}
              />}

          </Container>
      </View>
  )
}

export default EditClientScreen